.frozenfavs {
    p.footer-text:before {
        content: "®2023 Conagra Brands, Inc. All Rights Reserved. Bertolli® is a trademark of Mizkan America, Inc. and is used under license. P.F. Chang's® and P.F. Chang's Home Menu® are trademarks of P.F. Chang's China Bistro, Inc. and are used under license.";
    }

    .header {
        position: relative;
    }
    .garden {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        div img {
            max-height: none;
            width: 27%;
            position: absolute;
            bottom: 14%;
            left: 36%;
        }
        @media screen and (max-width:767px) {
            div img {
                width: 35%;
                bottom: 12%;
                left: 32%;
            }
        }
    }
}